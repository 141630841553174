/* eslint-disable max-len */
/* jscs:disable maximumLineLength */
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { isDatabaseBirthDate } from '../../core/util/time';
import { isDev } from '../../config/';

const monthNumber = 'M';
const monthAbbv = 'MMM';
const monthName = 'MMMM';
const dayNumber = 'D';
const dayNumberWithOrdinal = 'Do';
const dayAbbv = 'ddd';
const yearNumber = 'YYYY';
const militaryHour = 'HH';
const hour = 'h';
const minute = 'mm';
const second = 'ss';
const period = 'a';
const timeZone = 'z';
const weekDay = 'dddd';

export const MONTH_DAY_YEAR = `${monthNumber}/${dayNumber}/${yearNumber}`;
export const MONTH_DAY = `${monthNumber}/${dayNumber}`;
export const HOUR_MINUTE = `${hour}:${minute}`;
export const HOUR_MINUTE_SECOND = `${HOUR_MINUTE}:${second}`;
export const MILITARY_HOUR_MINUTE_SECOND = `${militaryHour}:${minute}:${second}`;
export const PERIOD_TIME_ZONE = `${period} ${timeZone}`;
export const TIME_ZONE = `${timeZone}`;
export const WEEK_DAY = `${weekDay}`;

export const DAY_ABBV_MONTH_DAY_YEAR = `${dayAbbv}, ${MONTH_DAY_YEAR}`;
export const HOUR_MINUTE_PERIOD = `${HOUR_MINUTE} ${period}`;
export const HOUR_MINUTE_PERIOD_NO_SPACE = `${HOUR_MINUTE}${period}`;

export const MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD = `${MONTH_DAY_YEAR} ${HOUR_MINUTE} ${period}`;
export const MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD_NO_SPACE = `${MONTH_DAY_YEAR} ${HOUR_MINUTE}${period}`;
export const MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD_TIME_ZONE = `${MONTH_DAY_YEAR} ${HOUR_MINUTE} ${PERIOD_TIME_ZONE}`;

export const MONTH_NAME_DAY_ABBR = `${monthName} ${dayNumberWithOrdinal}`;
export const MONTH_NAME_DAY_ABBR_YEAR = `${monthName} ${dayNumber}, ${yearNumber}`;
export const MONTH_ABBV_DAY = `${monthAbbv} ${dayNumber}`;
export const MONTH_ABBV_DAY_YEAR = `${monthAbbv} ${dayNumber}, ${yearNumber}`;
export const DAY_ABBV_MONTH_ABBV_DAY_YEAR = `${dayAbbv}, ${MONTH_ABBV_DAY_YEAR}`;
export const MONTH_NAME_YEAR = `${monthName} ${yearNumber}`;

export const MONTH_DAY_YEAR_FULL = 'MM/DD/YYYY';
export const MONTH_DAY_YEAR_FULL_TIMESTAMP = `${MONTH_DAY_YEAR_FULL} h:mm A zz`;

export const HOUR_MINUTE_PERIOD_TIME_ZONE = `${HOUR_MINUTE} ${PERIOD_TIME_ZONE}`;

export const YEAR_MONTH_DAY = `${yearNumber}/${monthNumber}/${dayNumber}`;
export const YEAR_MONTH_DAY_HOUR_MINUTE_SECOND = `${YEAR_MONTH_DAY} ${HOUR_MINUTE_SECOND}`;

export const APPOINTMENT_TIME_WITH_TIME_ZONE = MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD_TIME_ZONE;
export const BIRTH_DATE = MONTH_ABBV_DAY_YEAR;
export const PERIOD = period;

export const DAY_ABBR_HOUR_MINUTE = `${dayAbbv}, ${HOUR_MINUTE_PERIOD}`;
export const HOUR_PERIOD = `${hour}${period}`;

export const DATABASE_BIRTH_DATE_FORMAT = 'YYYY-MM-DD';

type Options = { hideTimeZoneIfSame?: boolean; parseFormat?: string; useLocalTime?: boolean };

const defaultOptions: Options = { hideTimeZoneIfSame: true, parseFormat: '', useLocalTime: false };

class DateTime extends PureComponent<{
  dateTime: string | number | any;
  format: string;
  timeZone?: string;
  options?: Options;
}> {
  static defaultProps = {
    format: MONTH_DAY_YEAR_HOUR_MINUTE_PERIOD,
    options: defaultOptions,
  };

  // handles UTC timestamps with timezone portion e.g. "2021-08-12T00:00:00+0:00"
  // and UTC timestmaps without timezone e.g. "2021-08-12T00:00:00"
  static parseTimestampWithTimezone = (timestamp: string, timeZoneIdentifier: string) => {
    const timezoneOffsetRegex = /[+-]\d{2}:\d{2}|Z$/;
    const hasTimezoneOffset = timezoneOffsetRegex.test(timestamp);
    if (hasTimezoneOffset) {
      return moment.tz(timestamp, timeZoneIdentifier);
    } else {
      return moment.tz(timestamp, 'UTC').tz(timeZoneIdentifier);
    }
  };

  /**
   *
   * @param {string} dateTime The date/time string to parse
   * @param {string} timeZoneIdentifier The timezone string (e.g. 'America/Chicago') to parse and format the date with
   * @param {object} options Custom options
   * @param {string} options.parseFormat custom parse format
   * @param {boolean} options.useLocalTime Display times in local time
   * @param {string} options.hideTimeZoneIfSame Hide timezones if user is already in that timezone
   * @returns {(format: string) => string} a formatter function
   */
  static formatDateTime =
    (dateTime: string | number | any, timeZoneIdentifier: string, options = defaultOptions) =>
    (format: string) => {
      let newDateTime = null;

      if (isDatabaseBirthDate(dateTime)) {
        newDateTime = moment(dateTime, DATABASE_BIRTH_DATE_FORMAT);
      } else if (options.parseFormat) {
        newDateTime = timeZoneIdentifier
          ? moment.tz(dateTime, options.parseFormat, timeZoneIdentifier)
          : moment(dateTime, options.parseFormat);
      } else {
        newDateTime = timeZoneIdentifier
          ? DateTime.parseTimestampWithTimezone(dateTime, timeZoneIdentifier)
          : moment(dateTime);
      }

      if (!newDateTime.isValid()) {
        if (isDev()) {
          throw new Error(
            `Moment did not like the dateTime provided to DateTime.formatDateTime, provided: ${dateTime}`
          );
        } else {
          return null;
        }
      }

      if (options.useLocalTime) {
        newDateTime = moment.tz(newDateTime, moment.tz.guess());
      }

      return newDateTime.format(DateTime.getFormat(format, timeZoneIdentifier, options));
    };

  static getFormat = (format: string, timeZoneIdentifier: string, options: Options) => {
    const userTimeZoneIdentifier = moment.tz.guess();

    if (options.hideTimeZoneIfSame && userTimeZoneIdentifier === timeZoneIdentifier) {
      return format.replace(` ${timeZone}`, '');
    }

    return format;
  };

  render = () => {
    const getFormattedDateTime = DateTime.formatDateTime(
      this.props.dateTime,
      this.props.timeZone ?? '',
      this.props.options
    );

    return <div>{getFormattedDateTime(this.props.format)}</div>;
  };
}

export default DateTime;
