import { Middleware } from 'redux';
import { MARK_CONVERSATION_READ } from '../../ducks/conversations';
import { store } from '../configureStore';
import { markConversationReadRemote } from '../../ducks/conversations.new';

export const ManageBroadcastChannel =
  typeof window !== 'undefined' && 'BroadcastChannel' in window
    ? new BroadcastChannel('manage')
    : null;

// Handle events from other browser contexts (tabs/windows/iframes)
if (ManageBroadcastChannel) {
  ManageBroadcastChannel.onmessage = (event) => {
    console.debug('broadcast channel message', event);
    if (event.data.type === 'conversationViewed') {
      // We use a separate 'remote' action to avoid re-broadcasting the same event
      store.dispatch(
        markConversationReadRemote({
          conversationId: event.data.conversationId,
          locationId: event.data.locationId,
          timestamp: event.data.timestamp,
        })
      );
    }
  };
}

// Send events to other browser contexts (tabs/windows/iframes)
export const broadcastChannelMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type === MARK_CONVERSATION_READ && ManageBroadcastChannel) {
    ManageBroadcastChannel?.postMessage({
      type: 'conversationViewed',
      conversationId: action.payload.conversationId,
      locationId: action.payload.locationId,
      timestamp: action.payload.timestamp,
    });
  }
  return next(action);
};
