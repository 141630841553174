import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import chunk from 'lodash/chunk';
import { Location, Review, RootState } from '../types/RootState';
import { buildFieldsQueryParam, QUEUE_LOCATIONS_FIELDS } from '../core/dapi/location';
import { shouldLogInToPubNub } from '../core/util/clinicAccounts';
import { locationsResponseFormatter } from '../reducers/formatters/location';
import {
  LOGIN_LOGOUT,
  ORIGIN_QUEUE,
  RESET_REVIEW_COUNTER,
  REVIEWS_DATA_ERROR,
  REVIEWS_DATA_RECEIVED,
  REVIEWS_NEW_REVIEWS_RECEIVED,
  STALE_REVIEWS_DATA,
} from '../constants';
import {
  getTotalUnreadCount,
  receiveConversation,
  receiveConversations,
  sendMessageSuccess,
  updatePatientConsentStatus,
  markConversationRead,
} from './conversations.new';
import reviewsReducer, {
  createInitialState as createInitialReviewsState,
} from '../reducers/reviews';
import { DapiDataResults, DapiResults } from '../core/dapi/response';
import { formatLocationName, getLocationsFromIds } from '../core/util/location';
import { getQueueSelectedLocationIds } from '../selectors/queue';
import { EMPTY_ARRAY, inArray } from '../core/util/array';
import { EMPTY_OBJECT } from '../core/util/object';
import { httpGetJson } from '../core/http/http';
import { DAPI_HOST } from '../config';
import { ReduxMigration, RunMigrationAction } from '../store/useReduxMigrate';
import { MARK_CONVERSATION_READ, MARK_CONVERSATION_READ_REMOTE } from './conversations';

export interface LocationState {
  results: { [key: string]: Location };
  loading: boolean;
  error: string | null;
  locationLoadingMap: Record<string, string>;
}

const initialState: LocationState = {
  error: null,
  loading: false,
  results: {},
  locationLoadingMap: {},
};

interface FetchLocationsDataRequest {
  locationIds: string[];
}

const Prefix = 'locations/fetch';
export const FetchLocationsFulfilledAction = `${Prefix}/fulfilled`;

export const fetchLocations = createAsyncThunk<Location[], FetchLocationsDataRequest>(
  Prefix,
  async (req: FetchLocationsDataRequest, thunk) => {
    if (req.locationIds.length === 0) {
      return [];
    }

    const state = thunk.getState() as RootState;
    const existingLocationIds = selectors.getLocationIds(state);

    // Fetch if we don't already have it and its not currently being loaded
    const toFetch = req.locationIds.filter(
      (locationId) =>
        !existingLocationIds.has(locationId) &&
        state.locations.locationLoadingMap[locationId] === thunk.requestId
    );

    if (toFetch.length === 0) {
      return [];
    }

    const roles = state.login?.account?.roles ?? [];

    const additionalFields: string[] = [];

    if (shouldLogInToPubNub(roles)) {
      additionalFields.push('pubnub_auth');
    }

    // Dapi has a max limit of 100, so we need to split into chunks of 100 and make seperate requests
    const chunks = chunk(toFetch, 100);

    const results = await Promise.all(
      chunks.map(async (ids) => {
        const idFilter = `filters=locations.id:${ids.join(',')}`;
        const { data } = await httpGetJson<DapiDataResults<Location>>(
          `${DAPI_HOST}/v4/locations/?${idFilter}&limit=${
            ids.length
          }&origin=${ORIGIN_QUEUE}${buildFieldsQueryParam(
            QUEUE_LOCATIONS_FIELDS,
            additionalFields
          )}`
        );
        return data.results;
      })
    );

    return locationsResponseFormatter(results.flat());
  }
);

export const locationsSlice = createSlice({
  initialState,
  name: 'locations',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(RunMigrationAction, (state, action) => {
      switch (action.payload) {
        case ReduxMigration.AddLocationType:
          for (let locationId of Object.keys(state.results)) {
            state.results[locationId].$type = 'location';
          }
          return state;
      }
    });
    builder.addCase(fetchLocations.pending, (state, action) => {
      for (let locationId of action.meta.arg.locationIds) {
        if (!state.locationLoadingMap[locationId]) {
          state.locationLoadingMap[locationId] = action.meta.requestId;
        }
      }
      state.loading = true;
    });
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.loading = false;
      for (const location of action.payload) {
        state.results[location.id] = location;
      }
      for (let locationId of action.meta.arg.locationIds) {
        delete state.locationLoadingMap[locationId];
      }
    });
    builder.addCase(fetchLocations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.stack || action.error.message || 'Something went wrong.';
      for (let locationId of action.meta.arg.locationIds) {
        delete state.locationLoadingMap[locationId];
      }
    });
    builder.addCase(receiveConversations, (state, action) => {
      const location = state.results[action.payload.locationId];
      for (let conversation of action.payload.value.results) {
        location.conversations.conversations[conversation.id] = conversation;
      }
      location.conversations.currentPage = action.payload.currentPage;
      location.conversations.hasMorePages = action.payload.hasMorePages;
      location.conversations.unreadCount = getTotalUnreadCount(
        location.conversations.conversations
      );
    });
    builder.addCase(receiveConversation, (state, action) => {
      const location = state.results[action.payload.locationId];
      const conversation = action.payload.value;
      location.conversations.conversations[conversation.id] = conversation;
      location.conversations.unreadCount = getTotalUnreadCount(
        location.conversations.conversations
      );
    });
    builder.addCase(sendMessageSuccess, (state, action) => {
      const message = action.payload.value;
      const conversationId = message.conversation_id;
      const location = state.results[action.payload.locationId];
      location.conversations.conversations[conversationId].most_recent_message = message;
      location.conversations.unreadCount = getTotalUnreadCount(
        location.conversations.conversations
      );
    });
    builder.addCase(updatePatientConsentStatus, (state, action) => {
      const data = action.payload.data;
      const location = state.results[action.payload.locationId];
      const conversation = location.conversations.conversations[data.conversation_id];
      const participant = conversation.participants.findIndex(
        (w) => w.account_id === data.account_id
      );
      if (participant !== -1) {
        conversation.participants[participant].is_consented_patient = data.is_consented_patient;
      }
      location.conversations.unreadCount = getTotalUnreadCount(
        location.conversations.conversations
      );
    });
    builder.addCase(LOGIN_LOGOUT, (state, action) => {
      state.results = {};
      state.error = null;
      state.loading = false;
    });
    builder.addMatcher(
      (w) => [MARK_CONVERSATION_READ, MARK_CONVERSATION_READ_REMOTE].includes(w.type as string),
      (state, action) => {
        const { locationId, conversationId, timestamp } = action.payload;

        const location = state.results[locationId];

        if (!location) return;

        const conversation = location.conversations?.conversations[conversationId];

        if (!conversation) return;

        // Remote events have a timestamp, we don't want to update if the last message is newer
        if (timestamp && conversation.most_recent_message) {
          const lastViewed = new Date(timestamp);
          const lastMessage = new Date(conversation.most_recent_message.created_date);
          if (lastViewed < lastMessage) {
            console.debug('not marking conversation read, last message is newer');
            return;
          }
        }

        conversation.unread_count = 0;
        location.conversations.unreadCount = getTotalUnreadCount(
          location.conversations.conversations
        );
      }
    );
    builder.addMatcher(
      (w) =>
        inArray(w.type, [
          RESET_REVIEW_COUNTER,
          REVIEWS_DATA_ERROR,
          REVIEWS_DATA_RECEIVED,
          STALE_REVIEWS_DATA,
          REVIEWS_NEW_REVIEWS_RECEIVED,
        ]),
      (
        state,
        action: PayloadAction<{
          locationId: string;
          value: DapiResults<Review>;
        }>
      ) => {
        const locationId = action.payload.locationId;
        if (!locationId) {
          return;
        }
        const location = state.results[locationId];
        if (!location) {
          return;
        }
        const reviews = location.reviews ? current(location.reviews) : undefined;
        location.reviews = reviewsReducer(reviews as any, action);
      }
    );
  },
});

const EMPTY_REVIEWS = createInitialReviewsState();

export const selectors = {
  getLocationIds(state: RootState): Set<string> {
    return new Set(Object.keys(state.locations.results));
  },
  getLocationsState(reduxState: RootState) {
    return reduxState?.locations ?? null;
  },
  getLocationsLoading(reduxState: RootState) {
    return selectors.getLocationsState(reduxState)?.loading ?? false;
  },
  getLocationsError(reduxState: RootState) {
    return selectors.getLocationsState(reduxState)?.error ?? null;
  },
  getLocation(reduxState: RootState, locationId: string) {
    return selectors.getLocationsState(reduxState)?.results?.[locationId] ?? null;
  },
  getLocationsFromIds(reduxState: RootState, locationIds: string[] = []) {
    return getLocationsFromIds(selectors.getLocationsState(reduxState)?.results ?? {}, locationIds);
  },
  getSelectedLocations(reduxState: RootState) {
    return selectors.getLocationsFromIds(reduxState, getQueueSelectedLocationIds(reduxState));
  },
  getLocationConversations(state: RootState, locationId: string) {
    const l = selectors.getLocation(state, locationId);
    return selectors.getLocation(state, locationId)?.conversations ?? null;
  },
  getLocationReviews(state: RootState, locationId: string) {
    return selectors.getLocation(state, locationId)?.reviews ?? EMPTY_REVIEWS;
  },
  getReviewCounterForLocation(state: RootState, locationId: string) {
    return selectors.getLocationReviews(state, locationId)?.reviewCounter ?? 0;
  },
  getReviewsDataForLocation(state: RootState, locationId: string) {
    return selectors.getLocationReviews(state, locationId)?.data ?? EMPTY_ARRAY;
  },
  getAggregateReviewCounterForSelectedLocations(state: RootState) {
    return getQueueSelectedLocationIds(state)
      .map((locationId: string) => selectors.getReviewCounterForLocation(state, locationId))
      .reduce((acc: any, next: any) => acc + next, 0);
  },
  getReviewsDataForLocationIsStale(state: RootState, locationId: string) {
    return selectors.getLocationReviews(state, locationId)?.stale ?? true;
  },
  getFormattedLocationName(state: RootState, locationId: string) {
    const location = selectors.getLocation(state, locationId);
    if (location == null) {
      return '';
    }
    return formatLocationName(location) || '';
  },
  getLocationServices(state: RootState, locationId: string) {
    return selectors.getLocation(state, locationId)?.servicesObj ?? EMPTY_OBJECT;
  },
};
