var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":{"create":"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\n\n"}};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { isProd, isLiveStage } from './src/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SOLV_ENV = process.env.SOLV_ENV || process.env.NEXT_PUBLIC_SOLV_ENV || 'unknown';

interface ErrorWithCause extends Error {
  cause?: {
    status?: number;
  };
}

function shouldIgnoreError(exception: Error | string | null | unknown): boolean {
  if (typeof exception === 'string' || exception === null) {
    return false;
  }

  const errorWithCause = exception as ErrorWithCause;
  const status = errorWithCause.cause?.status;
  // If we get a 400 level error from DAPI we assume it's being handled in the front-end
  return status !== undefined && status >= 400 && status < 500;
}

if (isProd() || isLiveStage()) {
  Sentry.init({
    dsn: SENTRY_DSN || 'https://82b1c71a417441dfb78798e1782f324a@o570890.ingest.sentry.io/107173',
    beforeSend(event, hint: Sentry.EventHint): Sentry.Event | null {
      const originalException = hint?.originalException;
      if (originalException && shouldIgnoreError(originalException)) {
        return null;
      }
      return event;
    },
    environment: SOLV_ENV,
    tracesSampleRate: 0.0,
    integrations: [
      Sentry.feedbackIntegration({ autoInject: false, colorScheme: 'light' }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
        beforeErrorSampling: () => {
          return false; // Never default sample the error; we'll flush manually if the error matches or randomly sampled
        },
      }),
    ],
  });

  try {
    const replay = Sentry.getReplay();
    replay?.startBuffering();
  } catch (e) {
    console.error(`Sentry replay start buffering error: ${e}`);
  }
}
