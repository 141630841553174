import { createAction } from '@reduxjs/toolkit';
import { DapiResults } from '../core/dapi/response';
import { Conversation, Participant, SentMessage } from '../types/RootState';
import {
  SEND_MESSAGE_SUCCESS,
  RECEIVE_CONVERSATION,
  RECEIVE_CONVERSATIONS,
  UPDATE_PATIENT_SMS_CONSENT_STATUS_SUCCESS,
  MARK_CONVERSATION_READ,
  MARK_CONVERSATION_READ_REMOTE,
} from './conversations';

export const getTotalUnreadCount = (conversations: Record<string, Conversation>) =>
  Object.values(conversations).reduce((acc, cur: any) => acc + cur.unread_count, 0);

export const receiveConversations = createAction<
  {
    value: DapiResults<Conversation>;
    locationId: string;
    currentPage: number;
    hasMorePages: boolean;
  },
  string
>(RECEIVE_CONVERSATIONS);

export const receiveConversation = createAction<
  {
    value: Conversation;
    locationId: string;
  },
  string
>(RECEIVE_CONVERSATION);

export const sendMessageSuccess = createAction<
  {
    locationId: string;
    value: SentMessage;
  },
  string
>(SEND_MESSAGE_SUCCESS);

export const updatePatientConsentStatus = createAction<
  {
    locationId: string;
    data: Participant;
  },
  string
>(UPDATE_PATIENT_SMS_CONSENT_STATUS_SUCCESS);

export const markConversationRead = createAction<
  {
    locationId: string;
    conversationId: string;
  },
  string
>(MARK_CONVERSATION_READ);

export const markConversationReadRemote = createAction<
  {
    locationId: string;
    conversationId: string;
    timestamp: string;
  },
  string
>(MARK_CONVERSATION_READ_REMOTE);
