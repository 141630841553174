import { isEmptyString } from './string';
import { isEmptyArray, SORT_DESC, sortAlphabetical } from './array';
import { EMPTY_STRING } from '../../constants';
import { isEmptyObject, safeGet } from './object';
import { dataBasePhoneValueToDisplay, isDatabasePhone } from './phoneNumber';

const getParticipantsOfType = (conversation, participantIdType) =>
  !isEmptyArray(conversation.participants) &&
  conversation.participants.filter(
    (participant) => !isEmptyString(participant[`${participantIdType}_id`])
  );

const getPatientConversationParticipantAttribute = (conversation, attribute) => {
  const patientParticipants = getParticipantsOfType(conversation, 'account');
  return safeGet(patientParticipants[0], null)(attribute);
};

const getAccountIdForConversationParticipant = (conversation) => {
  const patientParticipants = getParticipantsOfType(conversation, 'account');

  if (!isEmptyArray(patientParticipants)) {
    return patientParticipants[0].account_id;
  }

  return null;
};

const getConversationName = (conversation) => {
  if (!isEmptyString(conversation.name)) {
    return conversation.name;
  }

  if (isEmptyArray(conversation.participants)) {
    return 'New conversation';
  }

  return conversation.participants
    .filter((participant) => participant.account_id != null)
    .map((participant) =>
      `${participant.first_name || EMPTY_STRING} ${participant.last_name || EMPTY_STRING}`.trim()
    )
    .filter((name) => !isEmptyString(name))
    .map((phone) => (isDatabasePhone(phone) ? dataBasePhoneValueToDisplay(phone) : phone))
    .join(', ');
};

const getViewerParticipantId = (clinicAccountId, conversation) => {
  const locationParticipant = getParticipantsOfType(conversation, 'location')?.[0];
  return locationParticipant?.conversation_participant_id;
};

const getConversationsSortedByUpdatedDate = (conversations) =>
  sortAlphabetical(
    Object.values(conversations),
    (conversation) => conversation.updated_date,
    SORT_DESC
  );

const getConversationUnreadCountByBookingAccountId = (conversations, accountId) => {
  const conversationForBooking = Object.values(conversations?.conversations || {}).filter(
    (conversation) =>
      conversation.participants.some((participant) => participant.account_id === accountId)
  );

  if (!isEmptyArray(conversationForBooking)) {
    return conversationForBooking[0].unread_count;
  }

  return 0;
};

const getUnreadMessagesCount = (selectedLocations) => {
  if (isEmptyArray(selectedLocations)) return 0;
  return selectedLocations.reduce((sum, location) => sum + location.conversations.unreadCount, 0);
};

const getUnreadConversationsCount = (selectedLocations) => {
  if (isEmptyArray(selectedLocations)) return 0;
  const conversations = selectedLocations.flatMap((loc) =>
    Object.values(loc.conversations.conversations)
  );
  if (isEmptyArray(conversations)) return 0;
  const unreadConversations = conversations.filter((conversation) => conversation.unread_count > 0);
  return unreadConversations.length;
};

export {
  getAccountIdForConversationParticipant,
  getConversationName,
  getConversationsSortedByUpdatedDate,
  getViewerParticipantId,
  getConversationUnreadCountByBookingAccountId,
  getPatientConversationParticipantAttribute,
  getUnreadMessagesCount,
  getUnreadConversationsCount,
};
