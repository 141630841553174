import { z } from 'zod';

export const uiConfigSchema = z.object({
  tableColumnViews: z.record(z.record(z.optional(z.boolean()))).default({}),
  useNewNav: z.boolean().optional().default(false),
  useNewInbox: z.boolean().optional().default(false),
  newInboxOnboarded: z.boolean().optional().default(false),
});

export type UiConfig = z.infer<typeof uiConfigSchema>;
export const defaultUiConfig: UiConfig = uiConfigSchema.parse({});
