import { LoginToken } from '../../types/RootState';
import { queryStringFromObject } from '../util/string';

export type UniversalSessionCookie = {
  tokenType: string;
  authToken: string;
  refreshToken: string;
  accountId: string;
  persisted: boolean;
  expiresAt: string;
  lastRefresh: number;
  tokenVersion: number;
};

export const UNIVERSAL_SESSION_COOKIE_NAME = '__provider_session';

export function secondsUntil(date: Date) {
  return Math.floor((date.getTime() - Date.now()) / 1000);
}

/**
 * Get the shared session secret
 *
 * @throws if the secret is not set
 * @returns the secret key
 */
export function getSharedSessionSecret() {
  const secret = process.env.SHARED_SESSION_SECRET;

  const envType = typeof secret; // splitting this on two lines so the secret checker doesn't complain
  if (envType !== 'string') {
    throw new Error('SHARED_SESSION_SECRET is not set');
  }

  return secret as string;
}

/**
 * Persists the login information and auth token to a secure HttpOnly session shared
 * amongst all solv frontend projects.
 * @param session The session data to store
 * @param opts
 * @returns A promise that resolves when the session is stored
 */
export async function persistUniversalAuthSession(
  session: LoginToken,
  opts: {
    clearRemixCache: boolean;
  }
) {
  const response = await fetch(`/api/auth/store-login?${queryStringFromObject(opts)}`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(session),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    keepalive: true,
  });
  if (!response.ok) {
    const body = await response.json().catch(() => null);
    const description = body?.error ?? `${response.status} ${response.statusText}`;
    throw new Error(`Failed to store login: ${description}`);
  }
  return response;
}

/**
 * Sends a fetch request to delete the shared HttpOnly session.
 */
export function deleteUniversalAuthSession() {
  // Synchronous XHR to ensure cookies are deleted by the time we return (i.e. before a redirect)
  const xhr = new XMLHttpRequest();
  xhr.open('POST', '/api/auth/logout', false);
  xhr.send();
}

/**
 * Convert the universal cookie value into the shape expected by manage
 */
export const parseUniversalCookie = (cookie: UniversalSessionCookie): LoginToken => {
  return {
    token_type: cookie.tokenType,
    access_token: cookie.authToken,
    refresh_token: cookie.refreshToken,
    expires_in: secondsUntil(new Date(cookie.expiresAt)),
    clinic_account_id: cookie.accountId,
    token_version: cookie.tokenVersion ?? 1,
    expiration_timestamp: cookie.expiresAt,
    grant_type: 'refresh_token',
    meta: {
      last_refresh: cookie.lastRefresh,
    },
  };
};
