import {
  ORIGIN_BOOKING_WIDGET,
  ORIGIN_EXTERNAL_SCHEDULE_SYNC,
  ORIGIN_KIOSK_SIGN_IN,
  ORIGIN_MOBILE_APP,
  ORIGIN_PARTNER_API,
  ORIGIN_QUEUE,
  ORIGIN_QUEUE_WALK_IN,
} from '../../../../constants';

export enum Variant {
  DEFAULT = 'default',
  PARTNER_API = 'partner_api',
  KIOSK_QUEUE = 'kiosk_queue',
  BOOKING_RESERVED_STATUS = 'booking_reserved_status',
  EXTERNAL_TELEMED = 'external_telemed',
  TOKBOX_TELEMED = 'tokbox_telemed',
  PAPERWORK_PAYMENTS_NOT_REQUIRED = 'paperwork_payments_not_required',
  PAPERWORK_PAYMENTS_NOT_REQUIRED_ASAP = 'paperwork_payments_not_required_asap',
  PAPERWORK_PAYMENTS_REQUIRED_ASAP = 'paperwork_payments_required_asap',
  PAPERWORK_PAYMENTS_REQUIRED = 'paperwork_payments_required',
  PAPERWORK_ONLY_REQUIRED_ASAP = 'paperwork_only_required_asap',
  PAPERWORK_ONLY_REQUIRED_PG = 'paperwork_only_required_pg',
  PAPERWORK_ONLY_REQUIRED = 'paperwork_only_required',
  PAYMENT_ONLY_REQUIRED_ASAP = 'payment_only_required_asap',
  PAYMENT_ONLY_REQUIRED = 'payment_only_required',
  DEFAULT_TOKBOX_TELEMED = 'default_tokbox_telemed',
  EXTERNAL_TELEMED_PG = 'external_telemed_pg',
  APP_DOWNLOAD_DRIVER = 'app_download_driver',
  ASAP_TELEMED = 'asap_telemed',
  CONTACTLESS_WALK_IN = 'contactless_walk_in',
  FAMILY_BOOKING = 'family_booking',
  TELEMED_PG = 'telemed_pg',
  TELEMED = 'telemed',
  RESCHEDULED_PG = 'rescheduled_pg',
  RESCHEDULED = 'rescheduled',
  IN_MARKETPLACE = 'in_marketplace',
  NO_NAME = 'no_name',
  DEFAULT_TELEMED = 'default_telemed',
  DEFAULT_PG = 'default_provider_group',
  DEFAULT_NO_REBOOKING_PROMPT = 'default_no_rebooking_prompt',
}

export const DEFAULT_VARIANTS = [
  Variant.DEFAULT,
  Variant.DEFAULT_NO_REBOOKING_PROMPT,
  Variant.DEFAULT_PG,
  Variant.DEFAULT_TELEMED,
];

export enum NotificationType {
  APPOINTMENT_REMINDER_PROMPT = 'APPOINTMENT_REMINDER_PROMPT',
  APPOINTMENT_REMINDER_PROMPT_CONFIRMED = 'APPOINTMENT_REMINDER_PROMPT_CONFIRMED',
  APPOINTMENT_REMINDER_PROMPT_CANCELLED = 'APPOINTMENT_REMINDER_PROMPT_CANCELLED',
  APPOINTMENT_REMINDER = 'APPOINTMENT_REMINDER',
  APPOINTMENT_CHECK_IN = 'APPOINTMENT_CHECK_IN',
  BOOKING_CANCELLATION_CONFIRMATION = 'BOOKING_CANCELLATION_CONFIRMATION',
  BOOKING_ONLINE_WAITLIST_CHECK_IN_REMINDER = 'BOOKING_ONLINE_WAITLIST_CHECK_IN_REMINDER',
  POST_VISIT_SUMMARY_SMS = 'POST_VISIT_SUMMARY_SMS',
  PROMPT_FOR_REVIEW_SMS_ONE = 'PROMPT_FOR_REVIEW_SMS_ONE',
  PROMPT_FOR_REVIEW_SMS_TWO = 'PROMPT_FOR_REVIEW_SMS_TWO',
  KIOSK_SIGN_IN_CONFIRMATION = 'KIOSK_SIGN_IN_CONFIRMATION',
  BOOKING_CONFIRMATION = 'BOOKING_CONFIRMATION',
  PAPERWORK_LINK = 'PAPERWORK_LINK',
  REVIEW_RESPONSE = 'REVIEW_RESPONSE',
}

/**
 * camel-case versions of the config_names from the general_configs table
 * that correspond to booking-related SMS and email NotificationTypes
 * in dapi-tasks.
 */
export enum CommToggleName {
  APPOINTMENT_CHECK_IN = 'appointmentCheckInSms',
  APPOINTMENT_REMINDER = 'appointmentReminderSms',
  APPOINTMENT_REMINDER_PROMPT = 'appointmentReminderPromptSms',
  BOOKING_CANCELLATION_CONFIRMATION = 'bookingCancellationConfirmationSms',
  BOOKING_CANCELLATION_CONFIRMATION_EMAIL = 'bookingCancellationConfirmationEmail',
  BOOKING_CONFIRMATION = 'bookingConfirmationSms',
  BOOKING_CONFIRMATION_EMAIL = 'bookingConfirmationEmail',
  BOOKING_ONLINE_WAITLIST_CHECK_IN_REMINDER_SMS = 'bookingOnlineWaitlistCheckInReminderSms',
  DISCHARGE_GOOGLE_REVIEW_EMAIL = 'dischargeGoogleReviewEmail',
  PROMPT_FOR_REVIEW_SMS_ONE = 'promptForReviewSmsOne',
  PROMPT_FOR_REVIEW_SMS_TWO = 'promptForReviewSmsTwo',
  KIOSK_SIGN_IN_CONFIRMATION = 'kioskSignInConfirmationSms',
  ONBOARDING_EMAIL = 'onboardingEmail',
  PAPERWORK_LINK = 'paperworkLinkSms',
  POST_VISIT_SUMMARY_SMS = 'postVisitSummarySms',
  REVIEW_RESPONSE = 'reviewResponseSms',
  WRAP_UP_EMAIL = 'wrapUpEmail',
}

type NotificationTypeToCamelCase = {
  [key in NotificationType]: string;
};

// Just a mapping of notification types to CamelCase versions of the same strings
export const NOTIFICATION_TYPES_CAMEL_CASE = {
  [NotificationType.APPOINTMENT_REMINDER_PROMPT]: 'AppointmentReminderPrompt',
  [NotificationType.APPOINTMENT_REMINDER_PROMPT_CONFIRMED]: 'AppointmentReminderPromptConfirmed',
  [NotificationType.APPOINTMENT_REMINDER_PROMPT_CANCELLED]: 'AppointmentReminderPromptCancelled',
  [NotificationType.APPOINTMENT_REMINDER]: 'AppointmentReminder',
  [NotificationType.APPOINTMENT_CHECK_IN]: 'AppointmentCheckIn',
  [NotificationType.BOOKING_CANCELLATION_CONFIRMATION]: 'BookingCancellationConfirmation',
  [NotificationType.BOOKING_ONLINE_WAITLIST_CHECK_IN_REMINDER]:
    'BookingOnlineWaitlistCheckInReminder',
  [NotificationType.POST_VISIT_SUMMARY_SMS]: 'PostVisitSummarySms',
  [NotificationType.PROMPT_FOR_REVIEW_SMS_ONE]: 'PromptForReviewSmsOne',
  [NotificationType.PROMPT_FOR_REVIEW_SMS_TWO]: 'PromptForReviewSmsTwo',
  [NotificationType.KIOSK_SIGN_IN_CONFIRMATION]: 'KioskSignInConfirmation',
  [NotificationType.BOOKING_CONFIRMATION]: 'BookingConfirmation',
  [NotificationType.PAPERWORK_LINK]: 'PaperworkLink',
  [NotificationType.REVIEW_RESPONSE]: 'ReviewResponse',
} as NotificationTypeToCamelCase;

export enum CustomizeCommSection {
  EDIT = 'edit',
  MESSAGE = 'message',
  HISTORY = 'history',
}

export interface PartialComm {
  toggleName?: CommToggleName;
  notificationType: NotificationType;
  title: string;
  description: string;
}

export type Comm = Required<PartialComm>;

export interface CommSubSection {
  name: string;
  tooltip: string;
  comms: NotificationType[];
}

export interface CommSection {
  name: string;
  subSections: CommSubSection[];
}

export interface TabFormRef {
  submitForm: () => void;
}

export const BOOKING_ORIGIN_TO_DISPLAY_NAME = {
  [ORIGIN_BOOKING_WIDGET]: 'Booking Widget',
  [ORIGIN_KIOSK_SIGN_IN]: 'Kiosk',
  [ORIGIN_MOBILE_APP]: 'Mobile App',
  [ORIGIN_PARTNER_API]: 'Partner API',
  [ORIGIN_QUEUE]: 'Queue (Call ahead)',
  [ORIGIN_QUEUE_WALK_IN]: 'Queue (Walk in)',
  [ORIGIN_EXTERNAL_SCHEDULE_SYNC]: 'External Schedule Sync',
} as const;

export type BookingOrigin = keyof typeof BOOKING_ORIGIN_TO_DISPLAY_NAME;
