import { takeLatest } from 'redux-saga/effects';
import {
  fetchInsuranceSummary,
  submitInsuranceForm,
  fetchInsurersList,
} from '../components/FacesheetModal/Payments/POS/POSSagas';

export default function* rootSaga() {
  yield takeLatest('FETCH_INSURANCE_SUMMARY', fetchInsuranceSummary);
  yield takeLatest('SUBMIT_INSURANCE_SUMMARY', submitInsuranceForm);
  yield takeLatest('FETCH_INSURERS_LIST', fetchInsurersList);
}
