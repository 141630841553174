import BulkUpload from './BulkUpload';

import EditPaymentOptions from './EditPaymentOptions';

import ClinicAccountForm from './ClinicAccountForm';
import ServicePreferences from './core/servicePreferences';
import ProviderLocations from './core/providerLocations';
import Contacts from './core/contacts';
import ServiceLocations from './core/serviceLocations';
import ImageUpload from './ImageUpload';
import Practices from './core/practices';
import Providers from './core/providers';
import Vaccinations from './core/vaccinations';
import LabResults from './core/labResults';
import SingleUseLinks from './SingleUseLinks';
import Kiosk from './Kiosk';
import DownloadReports from './DownloadReports';
import Packages from './core/packages';
import Images from './core/images';
import Reviews from './Reviews';
import GoogleReviews from './core/reviewsGoogleGmb';
import SolvRatings from './core/solvRatings';
import ConsentFormUpload from './ConsentFormUpload';
import LocationConsentForms from './core/locationConsentForms';
import Categories from './core/categories';
import ChangeLocationOrTimeModal from './ChangeLocationOrTimeModal';
import Handoffs from './core/handoffs';
import Paperwork from './Paperwork';
import InsuranceProfiles from './core/insuranceProfiles';
import CurrentPatient from './CurrentPatient';
import WebPushSubscriptions from './core/webPushSubscriptions';
import uclBrands from './uclBrands';
import Specialties from './core/specialties';
import MarketplaceInsurers from './core/marketplaceInsurers';
import PlatformUsageAlerts from './PlatformUsageAlerts';
import Reports from './Reports';
import Conversations from './Conversations';
import SolvPatientBoost from './SolvPatientBoost';
import Accounts from './core/accounts';
import Runtime from './core/runtime';
import Bookings from './core/bookings';
import FacesheetModal from './FacesheetModal';
import BulkAccountCreation from './BulkAccountCreation';
import LocationSettings from './LocationSettings';
import SftpExportConfig from './core/sftpExportConfig';
import POS from './POS';

export default [
  BulkUpload,
  POS,
  EditPaymentOptions,
  LocationSettings,
  BulkAccountCreation,
  FacesheetModal,
  Bookings,
  Runtime,
  Accounts,
  SolvPatientBoost,
  Conversations,
  Reports,
  PlatformUsageAlerts,
  MarketplaceInsurers,
  Specialties,
  uclBrands,
  WebPushSubscriptions,
  CurrentPatient,
  InsuranceProfiles,
  Paperwork,
  Handoffs,
  ChangeLocationOrTimeModal,
  Categories,
  LocationConsentForms,
  ConsentFormUpload,
  SolvRatings,
  GoogleReviews,
  Reviews,
  Images,
  Packages,
  DownloadReports,
  Kiosk,
  SingleUseLinks,
  LabResults,
  Vaccinations,
  Providers,
  Practices,
  ImageUpload,
  ServiceLocations,
  Contacts,
  ProviderLocations,
  ServicePreferences,
  ClinicAccountForm,
  SftpExportConfig,
];
