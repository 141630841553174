import {
  DAPI_CACTUS_HOST,
  DAPI_CLOUDFRONT_HOST,
  DAPI_HOST,
  DEFAULT_SEARCH_RADIUS_IN_MILES,
  METERS_IN_A_MILE,
} from '../../config';
import { ORIGIN_QUEUE, PLATFORM_TYPE_PROVIDER_GROUP, VERSION_TWO } from '../../constants';
import { getFilterString } from '.';
import { isEmpty } from '../util/empty';
import { queryStringFromObject } from '../util/string';
import { safeGet } from '../util/object';
import { CamelCase, GenericObject } from '../util/generics';
import type Location from '@solvhealth/types/interfaces/Location';

const SETTINGS_LOCATIONS_FIELDS = 'settingsLocationsFields';
const KIOSK_LOOKUP_FIELDS = 'kioskLookupFields';
const QUEUE_LOCATIONS_FIELDS = 'queueLocationsFields';
const ADD_PATIENT_MODAL_FIELDS = 'addPatientModalFields';
const PROVIDER_GROUP_PRACTICE_LOCATIONS_FIELDS = 'pgPracticeLocationsFields';
const GROUP_EDIT_FIELDS = 'groupEditFields';
const REVIEW_MODERATION_FIELDS = 'reviewModerationFields';
const KIOSK_V3_FIELDS = 'generalConfigFields';
const TV_WAITLIST_FIELDS = 'tvWaitListFields';

const LOCATION_SEARCH_FIELDS = [
  'id',
  'display_name_primary',
  'display_name_secondary',
  'display_address',
  'address',
  'subpremise',
  'city',
  'state',
  'zip_code',
  'phone',
  'is_telemed',
  'time_zone',
  'groups',
  'wait_list_count',
  'lat_long',
  'distance_from_current_location',
  'hours',
  'is_book_ahead_on_walk_in_slots',
  'facade_type',
  'groups',
];

const fieldSets = {
  [SETTINGS_LOCATIONS_FIELDS]: [
    'address',
    'appointment_interval',
    'appointment_interval_reservations',
    'appointment_slots',
    'city',
    'disable_reservations_until',
    'display_name_primary',
    'display_name_secondary',
    'groups',
    'hours',
    'hours_default',
    'id',
    'is_solv_partner',
    'is_test_location',
    'location_id_numeric',
    'package',
    'payment_account_id',
    'platform_type',
    'special_operating_hours',
    'state',
    'time_zone',
    'zip_code',
    'wait_time',
  ],
  [GROUP_EDIT_FIELDS]: ['id', 'display_name_primary', 'display_name_secondary', 'package'],
  [REVIEW_MODERATION_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
    'is_solv_partner',
  ],
  [KIOSK_LOOKUP_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
    'is_solv_partner',
    'is_test_location',
    'offboarded_date',
  ],
  [QUEUE_LOCATIONS_FIELDS]: [
    'address',
    'booking_url',
    'city',
    'display_address',
    'display_name_primary',
    'display_name_secondary',
    'distance_from_current_location',
    'groups',
    'id',
    'image_links',
    'is_booking_widget_address_required',
    'is_asap_telemed_enabled',
    'is_birth_sex_required',
    'is_book_ahead_on_walk_in_slots',
    'is_canned_text_chat_only_enabled',
    'is_consent_enabled',
    'is_kiosk_custom_questions_enabled',
    'is_mobile_check_in_enabled',
    'is_paperless_handoff_enabled',
    'is_paperwork_enabled',
    'is_paperwork_required',
    'is_payments_enabled',
    'is_payments_required',
    'is_photo_id_back_required',
    'is_photo_id_front_required',
    'is_photo_id_upload_enabled',
    'is_smooch_enabled',
    'is_standalone_telemed',
    'is_telemed',
    'is_test_location',
    'is_video_visit_handoff_enabled',
    'lat_long',
    'offboarded_date',
    'office_id',
    'phone',
    'platform_type',
    'service_locations',
    'services_obj',
    'services',
    'specialties',
    'state',
    'subpremise',
    'time_zone',
    'tracking_properties',
    'website',
    'zip_code',
    'booking_widget_languages',
    'package_name',
    'is_performance_pricing_enabled',
    'live_date',
    'performance_pricing_monthly_budget',
    'has_boost',
    'twilio_phone',
    'contracted_booking_page_engagement',
    'performance_pricing_overage_cap',
    'performance_pricing_overage_bpe_cost',
    'is_family_bookings_enabled',
    'is_email_required_for_booking_widget',
    'is_custom_quick_reply_enabled',
    'is_external_telemed',
    'facade_location_ids',
    'facade_locations',
  ],
  [ADD_PATIENT_MODAL_FIELDS]: ['hours', 'wait_list_count'],
  [PROVIDER_GROUP_PRACTICE_LOCATIONS_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
  ],
  [KIOSK_V3_FIELDS]: ['id', 'use_kiosk_v3'],
  [TV_WAITLIST_FIELDS]: [
    'id',
    'display_name_primary',
    'display_name_secondary',
    'display_time_tv_wait_list',
  ],
} as const;

type FieldSetName = keyof typeof fieldSets;
type Opts = { [key: string]: any; fieldSet?: FieldSetName };
type SearchParams = { [key: string]: any };

export type LocationFields<S extends FieldSetName> = Pick<
  Location,
  Extract<keyof Location, CamelCase<typeof fieldSets[S][number]>>
>;

export type GroupEditLocation = LocationFields<'groupEditFields'>;

export type GroupConnectConfig = {
  group_id: number;
  connect_budget_limit: number | null;
  connect_overage_amount: number | null;
  connect_overage_engagement_cost: number | null;
  connect_overage_engagements: number | null;
  connect_enabled: boolean;
  total_budgeted_amount: number;
  location_ids: number[];
};

export const buildGenericFieldsQueryParam = (fieldNamesList: string[] = []) =>
  `&fields=${fieldNamesList.join(',')}`;

export const buildFieldsQueryParam = (fieldSet: FieldSetName, additionalFields: string[] = []) =>
  buildGenericFieldsQueryParam(additionalFields.concat(fieldSets[fieldSet]));

const getIsSolvPartnerLocationsUrl = (opts: Opts = {}) => {
  const version = opts.version || 'v3';
  // `should_apply_account_filter` is only supported in the v3 locations endpoint
  const shouldApplyAccountFilter = version === 'v3' ? '&should_apply_account_filter=true' : '';
  const url = `${DAPI_HOST}/${version}/locations?filters=locations.is_solv_partner:true${shouldApplyAccountFilter}`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getPartnerAndNonPartnerLocationsUrl = (opts: Opts = {}) => {
  const url = `${DAPI_HOST}/${opts?.version || 'v3'}/locations?`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getPaginatedLocationsUrl = (opts: Opts) => {
  const {
    isPartner = true,
    fieldSet,
    page = 1,
    limit = 100,
    orderBy = `display_name_primary ASC`,
    search,
    groupIds,
  } = opts;

  const base = `${DAPI_HOST}/v4/locations`;

  const fieldString = fieldSet ? `${buildFieldsQueryParam(fieldSet)}&` : '';

  const filterString = getFilterString('locations', {
    is_solv_partner: isPartner,
    location_name_textsearchable: search,
  });

  const queryString = queryStringFromObject(
    { page, order_by: orderBy, limit, group_ids: groupIds?.join(',') },
    { excludeNullish: true }
  );

  return `${base}?${fieldString}${filterString}&${queryString}`;
};

const getIsSolvEmployeeLocationsUrlMultipleParams = (
  searchParams: SearchParams,
  opts: Opts = {}
) => {
  const locationSearchParams = { ...searchParams };
  delete locationSearchParams.group_id;
  delete locationSearchParams.zip_code;

  let url = `${DAPI_HOST}/v3/locations?${getFilterString('locations', locationSearchParams)}`;

  if (searchParams.group_id) {
    url += `&group_ids=${searchParams.group_id}`;
  }

  if (searchParams.zip_code) {
    url += `&zip_code=${searchParams.zip_code}`;
  }

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getLocationUrl = (
  locationId: string,
  origin = ORIGIN_QUEUE,
  version = VERSION_TWO,
  opts: Opts = {}
) => {
  const url = `${DAPI_HOST}/${version}/locations/${locationId}?origin=${origin}`;

  if (opts.fieldSet) {
    return `${url}${buildFieldsQueryParam(opts.fieldSet)}`;
  }

  return url;
};

const getCachedLocationUrl = (locationId: string, origin = ORIGIN_QUEUE, version = VERSION_TWO) =>
  `${DAPI_CLOUDFRONT_HOST}/${version}/locations/${locationId}?origin=${origin}`;

const getLocationsSearchUrl = (
  searchParameters: SearchParams,
  searchRadius = DEFAULT_SEARCH_RADIUS_IN_MILES * METERS_IN_A_MILE,
  origin = ORIGIN_QUEUE
) => {
  let url =
    `${DAPI_CACTUS_HOST}/v4/locations?limit=50&is_viewable=true&radius=${searchRadius}` +
    `&origin=${origin}&filters=locations.is_telemed:false&fields=${LOCATION_SEARCH_FIELDS}`;
  if (searchParameters.latitude && searchParameters.longitude) {
    url = `${url}&lat=${searchParameters.latitude}&long=${searchParameters.longitude}`;
  } else if (searchParameters.zipCode) {
    url = `${url}&zip_code=${searchParameters.zipCode}`;
  } else {
    return null;
  }

  if (searchParameters.groupIds) {
    url = `${url}&group_ids=${searchParameters.groupIds}`;
  }

  if (searchParameters.clinicAccountId) {
    url = `${url}&clinic_account_id=${searchParameters.clinicAccountId}`;
  }

  if (searchParameters.isNotOffBoarded) {
    url = `${url}&is_not_off_boarded=${searchParameters.isNotOffBoarded}`;
  }
  return url;
};

const getLocationsByOfficeIdUrl = (officeId: string) =>
  `${DAPI_HOST}/v4/locations?office_ids=${officeId}`;

const getLocationsByGroupsUrl = (groupIds: string, opts = {}) =>
  `${getIsSolvPartnerLocationsUrl(opts)}&group_ids=${groupIds}`;

const getPatchLocationDataUrl = (locationId: string) => `${DAPI_HOST}/v1/locations/${locationId}`;

const getPatchLocationUclBrandsAssignmentUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/locations/${locationId}/ucl-brand-assignment`;

const getLocationSpecialOperatingHoursUrl = (locationId: string, hoursId = '') =>
  `${DAPI_HOST}/v1/locations/${locationId}/operating-hours-special/${hoursId}`;

const getLocationSlotsUrl = (
  locationId: string,
  origin: string,
  onDate: string,
  visitTypeId?: string
) =>
  `${DAPI_HOST}/v1/locations/${locationId}/slots?origin=${origin}&on_date=${onDate}${
    visitTypeId ? `&visit_type_id=${visitTypeId}` : ''
  }`;

const getLocationReservationHoursUrl = (locationId: string, reservationHoursId = '') =>
  `${DAPI_HOST}/v1/locations/${locationId}/reservation-hours/${reservationHoursId}`;

const getLocationAvailabilityRecommendationsUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/locations/${locationId}/availability-recommendations`;

const getLocationOperatingHoursUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/locations/${locationId}/operating-hours`;

const getLocationBookingCodesUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/locations/${locationId}/booking-codes`;

const addLocationUrl = () => `${DAPI_CACTUS_HOST}/v1/locations`;

const getProcessImagesUrl = (entityId: string, entityType: any) =>
  `${DAPI_CACTUS_HOST}/v1/${entityType}s/${entityId}/process-images`;

const getInvalidateCloudfrontCacheUrl = (locationId: string) =>
  `${DAPI_HOST}/v1/locations/${locationId}/invalidate-cloudfront-cache`;

const getTelemedLocationSearchUrl = (groupId: string) =>
  `${DAPI_CACTUS_HOST}/v4/locations?limit=50&group_ids=${groupId}&filters=locations.is_telemed:true&fields=${LOCATION_SEARCH_FIELDS}`;

const getLocationCategoriesUrl = () => `${DAPI_HOST}/v1/location-categories`;

const getLocationServicePreferencesUrl = (queryParams: GenericObject) => {
  const queryString = isEmpty(queryParams) ? '' : `?${queryStringFromObject(queryParams)}`;
  return `${DAPI_HOST}/v1/locations/service-preferences${queryString}`;
};

const getPhysicalLocationsInStateByGroupUrl = (stateCode?: string, groupIds?: string[]) =>
  `${getIsSolvPartnerLocationsUrl({
    version: 'v4',
  })};locations.is_telemed:false;locations.state:${stateCode}&group_ids=${groupIds}`;

const getTelemedLocationsInStateByGroupUrl = (stateCode: string, groupId: string) =>
  `${getIsSolvPartnerLocationsUrl({
    version: 'v4',
  })};locations.is_telemed:true;locations.state:${stateCode}&group_ids=${groupId}`;

const getUpdateLocationAssociationsUrl = () => `${DAPI_HOST}/v1/locations/location-associations`;

const getProviderGroupLocationsUrl = () =>
  `${getIsSolvPartnerLocationsUrl({
    version: 'v4',
  })};locations.platform_type:${PLATFORM_TYPE_PROVIDER_GROUP}&fields=${
    fieldSets[PROVIDER_GROUP_PRACTICE_LOCATIONS_FIELDS]
  }`;

export {
  fieldSets,
  getLocationUrl,
  getCachedLocationUrl,
  getIsSolvPartnerLocationsUrl,
  getIsSolvEmployeeLocationsUrlMultipleParams,
  getLocationsByGroupsUrl,
  getLocationsSearchUrl,
  getLocationSlotsUrl,
  getLocationSpecialOperatingHoursUrl,
  getPatchLocationUclBrandsAssignmentUrl,
  getLocationReservationHoursUrl,
  getPatchLocationDataUrl,
  getLocationOperatingHoursUrl,
  getLocationBookingCodesUrl,
  addLocationUrl,
  KIOSK_LOOKUP_FIELDS,
  SETTINGS_LOCATIONS_FIELDS,
  QUEUE_LOCATIONS_FIELDS,
  ADD_PATIENT_MODAL_FIELDS,
  GROUP_EDIT_FIELDS,
  REVIEW_MODERATION_FIELDS,
  KIOSK_V3_FIELDS,
  TV_WAITLIST_FIELDS,
  getProcessImagesUrl,
  getLocationAvailabilityRecommendationsUrl,
  getInvalidateCloudfrontCacheUrl,
  getTelemedLocationSearchUrl,
  getLocationsByOfficeIdUrl,
  getLocationCategoriesUrl,
  getLocationServicePreferencesUrl,
  getPhysicalLocationsInStateByGroupUrl,
  getTelemedLocationsInStateByGroupUrl,
  getUpdateLocationAssociationsUrl,
  getProviderGroupLocationsUrl,
  getPaginatedLocationsUrl,
};
